import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCurrentTask } from '../../adapters/task';
import { Button } from '../../elements/Button';
import { Loading } from '../../elements/Loading';
import bg from '../../assets/images/bg-dashboard.png';
import styles from './styles.module.scss';
import { currentUserName } from '../../atoms/auth';
import { useRecoilValue } from 'recoil';

export function Dashboard() {
  const [isLoading, setIsLoadingState] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const name = useRecoilValue(currentUserName);

  useEffect(() => {
    if (!location.pathname.includes('dashboard')) {
      navigate('/dashboard', { replace: true });
    }
  }, [location.pathname, navigate]);

  const handleStartShift = async () => {
    setIsLoadingState(true);
    try {
      const task = await getCurrentTask();
      if (task) {
        navigate(`/tasks/${task.id}`);
      } else {
        navigate('/pending');
      }
    } catch (e) {
      // The API returns 404s if there's no task. Fixed in the next release so revert this in a few days
      navigate('/pending');
      // NegativeToast(e instanceof EngageAPIError ? e.errors : 'Something went wrong');
    } finally {
      setIsLoadingState(false);
    }
  };

  if (isLoading) return <Loading />;
  return (
    <div className={styles.Container}>
      <div className={styles.Left}>
        <div className={styles.Description}>
          <h1 className={styles.Title}>Howdy, {name}!</h1>
          <span className={styles.Subtitle}>
            Welcome to Saleswhale Operator! By joining Saleswhale, you are now part of a very
            special family seeking to rapidly build a world-class human-in-the-loop AI sales
            assistant that serves hundreds of delighted customers world-wide. As an Operator, you
            help to run our core business logic by qualifying replies, thereby generating examples
            for the AI to train on. Ready?
          </span>
          <Button label="Start your session" onClick={handleStartShift} variant={styles.Button} />
        </div>
      </div>
      <div className={styles.Right}>
        <img alt="welcome logo" src={bg} />
      </div>
    </div>
  );
}
