import { format } from 'date-fns';
import { startCase } from 'lodash';
import { IStringifyOptions, stringify } from 'qs';
import sanitizeHtml from 'sanitize-html';

export const formattedDateTime = (d: Date | string | null | undefined, f = 'do MMM yy h:mm a') => {
  if (!d) {
    return 'N.A.';
  }
  if (typeof d === 'string') {
    const date = new Date(d);
    return format(date, f);
  }
  return format(d, f);
};
export const formattedDisplayName = (
  f: string | null | undefined,
  l: string | null | undefined
) => {
  let firstName = f;
  let lastName = l;

  if (f === null || f === undefined || (f && f.match(/([\uD800-\uDBFF][\uDC00-\uDFFF])/g))) {
    firstName = '';
  }
  if (l === null || l === undefined || (l && l.match(/([\uD800-\uDBFF][\uDC00-\uDFFF])/g))) {
    lastName = '';
  }

  return `${firstName} ${lastName}`.trim();
};
export const formattedBytes = (bytes: number) => {
  if (bytes === 0) return '0';
  const k = 1000,
    sizes = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toString()).toFixed(2) + ' ' + sizes[i];
};
export const formattedFileTypes = (s: string) => {
  const files: { [k: string]: string } = {
    'application/msword': 'doc',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'doc',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.template': 'doc',
    'application/vnd.ms-word.document.macroEnabled.12': 'doc',
    'application/vnd.ms-word.template.macroEnabled.12': 'doc',
    'application/vnd.ms-excel': 'xls',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xls',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.template': 'xls',
    'application/vnd.ms-excel.sheet.macroEnabled.12': 'xls',
    'application/vnd.ms-excel.template.macroEnabled.12': 'xls',
    'application/vnd.ms-excel.addin.macroEnabled.12': 'xls',
    'application/vnd.ms-excel.sheet.binary.macroEnabled.12': 'xls',
    'application/vnd.ms-powerpoint': 'ppt',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'ppt',
    'application/vnd.openxmlformats-officedocument.presentationml.template': 'ppt',
    'application/vnd.openxmlformats-officedocument.presentationml.slideshow': 'ppt',
    'application/vnd.ms-powerpoint.addin.macroEnabled.12': 'ppt',
    'application/vnd.ms-powerpoint.presentation.macroEnabled.12': 'ppt',
    'application/vnd.ms-powerpoint.template.macroEnabled.12': 'ppt',
    'application/vnd.ms-powerpoint.slideshow.macroEnabled.12': 'ppt',
    'application/vnd.ms-access': 'mdb',
    'application/x-iwork-keynote-sffkey': 'key',
    'application/x-iwork-pages-sffpages': 'page',
    'application/x-iwork-numbers-sffnumbers': 'num',
    'application/vnd.apple.keynote': 'key',
    'application/vnd.apple.pages': 'page',
    'application/vnd.apple.numbers': 'num',
    'application/zip': 'zip',
    'application/x-rar-compressed': 'zip',
    'application/x-7z-compressed': 'zip',
    'application/pdf': 'pdf',
    'text/plain': 'txt',
    'text/csv': 'csv',
    'image/jpg': 'jpg',
    'image/jpeg': 'jpg',
    'image/pjpeg': 'jpg',
    'image/png': 'png',
    'image/x-png': 'png',
    'image/gif': 'gif',
  };
  return files[s];
};

export const formattedMergeTag = (tag: string | null) => {
  if (!tag) return '';
  return tag
    .split('.')
    .map(s => startCase(s))
    .join(' ');
};

// this formats an object to a query string in the format that BE expects i.e. ?list_a=1&list_a=2&list_a=3&list_b[]=1&list_b[]=2&list_b[]=3&list_c=1,2,3
export const stringifyToQueryString = (params: any, config?: IStringifyOptions) => {
  return stringify(params, {
    encode: false,
    arrayFormat: 'brackets',
    ...config,
  });
};
const allowedTags = ['a', 'merge-tag', 'content-block', 'img', 'ol', 'ul', 's'];
export const htmlSanitizer = (html: string) => {
  return sanitizeHtml(html, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(allowedTags),
    allowedAttributes: {
      img: ['src', 'srcset', 'alt', 'title', 'width', 'height'],
      a: ['href', 'name', 'target'],
    },
  });
};
// Email body makes use of some very special html to display the truncate button so we need to preserve those
export const htmlSanitizerAllowAttrs = (html: string) => {
  return sanitizeHtml(html, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat([
      ...allowedTags,
      'button',
      'svg',
      'g',
      'circle',
    ]),
    allowedAttributes: false,
  });
};
