import classNames from 'classnames/bind';
import styles from './styles.module.scss';

interface Props {
  name: string;
  value: string;
  label: string;
  type: string;

  isError?: boolean;
  testId?: string;
  errorMessage?: string;

  onChange: (e: React.FormEvent<HTMLInputElement>) => any;
}
let cx = classNames.bind(styles);

export function Input({
  name,
  value,
  label,
  type = 'text',
  isError = false,
  errorMessage,
  onChange,
}: Props) {
  return (
    <div className={cx({ Container: true, Error: isError })}>
      <label className={styles.Label} htmlFor={name}>
        {label}
      </label>
      <input className={styles.Input} name={name} onChange={onChange} type={type} value={value} />
      {isError && <span className={styles.ErrorMessage}>{errorMessage}</span>}
    </div>
  );
}
