import { isEmpty } from 'lodash';
import { useState } from 'react';
import { TaskSchema } from '../../adapters/task';
import { Avatar } from '../../elements/Avatar';
import { Status } from '../../elements/Status';
import { ToggleButton } from '../../elements/ToggleButton';
import { htmlSanitizer } from '../../utils/formatting';
import styles from './EmailCard.module.scss';

interface Props {
  id: TaskSchema['id'];
  body: TaskSchema['body'];
  ccs: TaskSchema['ccs'];
  receivers: TaskSchema['receivers'];
  sender: TaskSchema['sender'];
  subject: TaskSchema['subject'];
  parsedEmail: TaskSchema['parsedEmail'];
  isDisputed?: boolean;
}
export function EmailCard({
  id,
  body,
  ccs,
  receivers,
  sender,
  subject,
  isDisputed,
  parsedEmail,
}: Props) {
  const hasParsedEmail = !!parsedEmail;
  const [isShowingOriginal, setIsShowingOriginal] = useState(!hasParsedEmail);
  const shouldShowParsedEmail = !isShowingOriginal && hasParsedEmail;
  const cleanBody = body
    .split('\n')
    .filter(
      sentence =>
        !sentence.match(/^Sender.*/) &&
        !sentence.match(/^To.*/) &&
        !sentence.match(/^CC: .*/) &&
        !sentence.match(/^wrote:/) &&
        !sentence.match(/^>.*/) &&
        !sentence.match(/^&gt;.*/)
    )
    .join('<br>')
    .trim();

  const _subject = shouldShowParsedEmail ? parsedEmail.subject : subject;
  const _body = htmlSanitizer(cleanBody);
  const t_body = shouldShowParsedEmail ? (
    <div className={styles.Body}>
      <p dangerouslySetInnerHTML={{ __html: parsedEmail.greeting }} />
      <p className={styles.ParsedBody} dangerouslySetInnerHTML={{ __html: parsedEmail.body }} />
      <p dangerouslySetInnerHTML={{ __html: parsedEmail.farewell }} />
    </div>
  ) : (
    <div className={styles.Body} dangerouslySetInnerHTML={{ __html: _body }} />
  );

  return (
    <div className={styles.Container}>
      <span className={styles.Subject}>
        {isDisputed && <Status color="red" label="Disputed" />} {_subject}
      </span>
      <div className={`${styles.Card}`}>
        <div className={styles.Meta}>
          <Avatar randomBg id={id} name={sender} />
          <div className={styles.Detail}>
            <div>{sender}</div>
            {!isEmpty(receivers) && <div>to: {receivers.join(', ')}</div>}
            {!isEmpty(ccs) && <div>ccs: {ccs.join(', ')}</div>}
          </div>
        </div>
        {t_body}
      </div>
      {hasParsedEmail && (
        <div>
          <ToggleButton checked={isShowingOriginal} onChange={() => setIsShowingOriginal(i => !i)}>
            Show original email
          </ToggleButton>
        </div>
      )}
    </div>
  );
}
