import './App.scss';
import { Login } from './routes/Login';
import { ToastContainer } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { Routes, Route } from 'react-router-dom';
import { Dashboard } from './routes/Dashboard';
import { Task } from './routes/Task';
import { Pending } from './routes/Pending';
import { isAppSetup } from './atoms/app';
import { Setup } from './routes/Setup';
import { authState } from './atoms/auth';
import { RootContainer } from './elements/RootContainer';
import { MainNav } from './elements/MainNav';

function App() {
  const isSetup = useRecoilValue(isAppSetup);
  const currentUser = useRecoilValue(authState);

  if (!isSetup) {
    return <Setup />;
  }
  const t_routes = currentUser.isAuthenticated ? (
    <>
      <MainNav />
      <RootContainer>
        <Routes>
          <Route element={<Task />} path="tasks/:id" />
          <Route element={<Dashboard />} path="dashboard" />
          <Route element={<Pending />} path="pending" />
          <Route element={<Dashboard />} path="*" />
        </Routes>
      </RootContainer>
    </>
  ) : (
    <Routes>
      <Route element={<Login />} path="*" />
    </Routes>
  );
  return (
    <div>
      <ToastContainer />
      {t_routes}
    </div>
  );
}

export default App;
