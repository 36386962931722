import { ChangeEvent, ReactNode } from 'react';
import { Icon } from '../Icon';
import './ToggleButton.scss';

interface Props {
  checked: boolean;
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
  label?: string;
  locked?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
}

export function ToggleButton({
  children,
  className,
  label,
  locked,
  required,
  checked,
  onChange,
  ...inputProps
}: Props) {
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    !locked && onChange && onChange(e);
  };
  return (
    <div className={`ToggleButton ${className ? className : ''}`}>
      {label && (
        <div
          className={`ToggleButton__label ${required ? 'ToggleButton__label--required' : ''}`}
          data-testid="ToggleButton__label"
        >
          {label}
        </div>
      )}
      <div className="ToggleButton__inputWrapper">
        <label>
          <input
            {...inputProps}
            checked={checked}
            onChange={handleOnChange}
            required={required}
            type="checkbox"
          />
          <div className="ToggleButton__toggle" />
        </label>
        {children && <span className="ToggleButton__text">{children}</span>}
        {locked && (
          <Icon
            className="ToggleButton__icon"
            fill="#fff"
            left={checked ? 8 : 24}
            name="lock"
            size={8}
            testId="ToggleButton__lockedIcon"
          />
        )}
      </div>
    </div>
  );
}
