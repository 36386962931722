import { IntentSchema } from '../../adapters/intent';
import { Prediction } from '../../adapters/task';
import styles from './PredictionCard.module.scss';

interface Props {
  predictionObject: IntentSchema;
  selectedPrediction: string | null;
  updatePrediction: (prediction: Prediction) => void;
}

export function PredictionCard({ predictionObject, selectedPrediction, updatePrediction }: Props) {
  const { code, name, description, icon } = predictionObject;
  const isSelected = code === selectedPrediction;

  return (
    <div
      className={`${styles.Card} ${isSelected ? styles.Active : ''}`}
      onClick={() => updatePrediction(code)}
    >
      <img alt={description} className={`${styles.Icon}`} src={icon} />
      <div className={styles.Info}>
        <p className={styles.Name}>{name}</p>
        <p className={styles.Desc}>{description} </p>
      </div>
      <div className={`${styles.Select} ${isSelected && styles.Active}`} />
    </div>
  );
}
