import { atom, selector } from 'recoil';
import { AuthSchema } from '../adapters/auth';

export const authState = atom({
  key: 'Auth.currentUser',
  default: {
    id: 0,
    email: '',
    name: '',
    yesterdayTasks: 0,
    token: '',
    isAuthenticated: false,
    shiftTasks: 0,
    expiresAt: '',
    role: 'normal',
  } as AuthSchema,
});
export const currentUserName = selector({
  key: 'Auth.currentUserName',
  get: ({ get }) => {
    const auth = get(authState);
    return auth.name;
  },
});
