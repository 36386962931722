import { useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useGetIntents } from '../../adapters/intent';
import { postPrediction, postSkipTask, Prediction, useGetTask } from '../../adapters/task';
import { authState } from '../../atoms/auth';
import { Button } from '../../elements/Button';
import { Loading } from '../../elements/Loading';
import { NegativeToast } from '../../elements/Toast';
import { EmailCard } from './EmailCard';
import { PredictionCard } from './PredictionCard';
import styles from './styles.module.scss';

export function Task() {
  const { id } = useParams<{ id: string }>();
  const { status: taskStatus, data: task } = useGetTask(id);
  const { status: intentStatus, data: intents } = useGetIntents();
  const [currentUser, setCurrentUser] = useRecoilState(authState);
  const [selectedPrediction, setSelectedPrediction] = useState<Prediction | null>(null);
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submitPrediction = async (id: string, prediction: Prediction) => {
    setIsSubmitting(true);
    try {
      await postPrediction(id, prediction);
      setCurrentUser(u => {
        return { ...u, shiftTasks: u.shiftTasks + 1 };
      });
      navigate('/pending');
    } catch ({ errors }) {
      NegativeToast(errors);
    } finally {
      setIsSubmitting(false);
    }
  };
  const skipTask = async (id: string) => {
    setIsSubmitting(true);
    try {
      await postSkipTask(id);
      setCurrentUser(u => {
        return { ...u, shiftTasks: u.shiftTasks + 1 };
      });
      navigate('/pending');
    } catch ({ errors }) {
      NegativeToast(errors);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!id || taskStatus === 'hasError' || intentStatus === 'hasError')
    return <Navigate replace to="/pending" />;
  if (taskStatus === 'isLoading' || intentStatus === 'isLoading') return <Loading />;
  if (!task) return null;

  const t_predictions = intents.map((i, id) => {
    return (
      <PredictionCard
        key={id}
        predictionObject={i}
        selectedPrediction={selectedPrediction}
        updatePrediction={i => setSelectedPrediction(i)}
      />
    );
  });
  return (
    <div className={styles.Container}>
      <div className={styles.AnnotationContainer}>
        <div className={styles.Predictions}>
          <h1>Select a topic rule</h1>
          {t_predictions}
          <div className={styles.Buttons}>
            <Button
              disabled={isSubmitting}
              label="Skip"
              onClick={() => skipTask(id)}
              variant={styles.SkipBtn}
            />
            <Button
              disabled={isSubmitting || !selectedPrediction}
              label={currentUser.role !== 'admin' ? 'Submit' : 'Set Ground Truth'}
              onClick={
                selectedPrediction ? () => submitPrediction(id, selectedPrediction) : () => {}
              }
              variant={styles.SubmitBtn}
            />
          </div>
        </div>
      </div>
      <div className={styles.EmailContainer}>
        <EmailCard
          body={task.body}
          ccs={task.ccs}
          id={task.id}
          isDisputed={task.state !== 'normal'}
          parsedEmail={task.parsedEmail}
          receivers={task.receivers}
          sender={task.sender}
          subject={task.subject}
        />
      </div>
    </div>
  );
}
