import classNames from 'classnames/bind';
import styles from './styles.module.scss';

interface Props {
  label: string;

  disabled?: boolean;
  type?: 'submit' | 'reset' | 'button';
  variant?: string;
  onClick?: () => void;
}

const cx = classNames.bind(styles);

export function Button({ label, type = 'button', disabled = false, variant, onClick }: Props) {
  return (
    <button
      className={classNames(cx({ Button: true }), variant)}
      disabled={disabled}
      onClick={onClick}
      type={type}
    >
      {label}
    </button>
  );
}
